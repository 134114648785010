import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";


// sections
// import ConceptSection from "../components/concept-section";
import LogoSection from "../components/logo-section";
import ColorSection from "../components/color-section";
import TypographySection from "../components/typography-section";


function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`brand`, `3fo`, `ArborSF`, `internal`]}
        title="Brand"
      />

{/* Intro */}
      <section className="text-left pt-24 md:w-5/6" id="intro">

        <h1 className="text-heading_color font-primary_font font-black italic text-5xl inline-block pt-16 uppercase" tabIndex="0">
          brand guidelines
        </h1>
        <p className="my-4" tabIndex="0">
          Welcome to our brand guidelines, a digital tool we’ve created to help make it a little easier 
          for you to maintain our brand. 
        </p>
        <p className="my-4" tabIndex="0">
          Here you&#39;ll find the foundational elements that create our Artisan Distributing brand identity. Consistency is key 
          in keeping our brand presence strong. Consistent and repetitive usage of these elements will create lasting recognition 
          and a memorable connection with our audience.
        </p>
      </section>

{/* Concept */}
      {/* <ConceptSection /> */}

{/* Logo section */}
      <LogoSection />

{/* Color Section */}
      <ColorSection />

{/* Typography Section */}
      <TypographySection />

    </Layout>
  );
}
export default IndexPage;
