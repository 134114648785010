import React from "react";

// images
import masterLogo from "../images/ART_LOGO_Primary.svg";
import logoMark from "../images/ART_LOGO-SYMBOL_Primary.svg";
import masterLogoMarine from "../images/ART_LOGO_Marine.svg";
import masterLogoArctic from "../images/ART_LOGO_Arctic.svg";
import masterLogoBlack from "../images/ART_LOGO_Black.svg";
import masterLogoWhite from "../images/ART_LOGO_White.svg";
import logoMarkMarine from "../images/ART_LOGO-SYMBOL_Marine.svg";
import logoMarkArctic from "../images/ART_LOGO-SYMBOL_Arctic.svg";
import logoMarkBlack from "../images/ART_LOGO-SYMBOL_Black.svg";
import logoMarkWhite from "../images/ART_LOGO-SYMBOL_White.svg";
import logoSymbolCircleMarine from "../images/ART_LOGO-SYMBOL-CIRCLE_Marine.svg";
import logoSymbolCircleArctic from "../images/ART_LOGO-SYMBOL-CIRCLE_Arctic.svg";
import logoSymbolCircleBlack from "../images/ART_LOGO-SYMBOL-CIRCLE_Black.svg";
import logoSymbolCircleWhite from "../images/ART_LOGO-SYMBOL-CIRCLE_White.svg";
import logoCorrect1 from "../images/ART_Logo-usage_correct1.svg";
import logoCorrect2 from "../images/ART_Logo-usage_correct2.svg";
import logoCorrect3 from "../images/ART_Logo-usage_correct3.svg";
import logoCorrect4 from "../images/ART_Logo-usage_correct4.svg";
import logoIncorrect1 from "../images/ART_Logo-usage_incorrect1.svg";
import logoIncorrect2 from "../images/ART_Logo-usage_incorrect2.svg";
import logoIncorrect3 from "../images/ART_Logo-usage_incorrect3.svg";
import logoIncorrect4 from "../images/ART_Logo-usage_incorrect4.svg";

function LogoSection() {
  return (
    <section className="text-left mt-20 w-full" id="logo">
      <div className="bg-white sticky top-0 z-10">
        <h1 className="heading-1" tabIndex="0">
          logo
        </h1>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Our logo is the most important representation of the Artisan brand and may be used in two formats.
      </p>
      <h2 className="heading-2" tabIndex="0">
        PRIMARY LOGOS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Our primary logo contains the Artisan name with integrated octopus while the logo symbol contains only the octopus emblem.
      </p>
      <div className="grid grid-cols-2 gap-8 my-4">
        <figure tabIndex="0">
          <img
            alt="primary brand logo image"
            className="block mx-auto w-full"
            src={masterLogo}
          />
          <figcaption className="text-sm mt-1 uppercase">master logo</figcaption>
        </figure>
        <figure tabIndex="0">
          <img
            alt="primary brand logo image mark"
            className="block mx-auto w-full"
            src={logoMark}
          />
          <figcaption className="text-sm mt-1 uppercase">logo symbol</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        LOGO
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        As often as possible, our logo should be used in full color over a white background. The logo may 
        also be used in each of the blue brand colors. Black and white logos are used when color is not applicable.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="master logo - marine"
            className="block mx-auto w-full"
            src={masterLogoMarine}
          />
          <figcaption className="text-sm mt-1 uppercase">Marine logo</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - arctic"
            className="block mx-auto w-full"
            src={masterLogoArctic}
          />
          <figcaption className="text-sm mt-1 uppercase">Arctic logo</figcaption>
        </figure>
        <figure>
          <img
            alt="logo image placeholder"
            className="block mx-auto w-full"
            src={masterLogoBlack}
          />
          <figcaption className="text-sm mt-1 uppercase">black logo</figcaption>
        </figure>
        <figure>
          <img
            alt="master logo - white"
            className="block mx-auto w-full"
            src={masterLogoWhite}
          />
          <figcaption className="text-sm mt-1 uppercase">white logo</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        SYMBOL
      </h2>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
        There are instances where the logo symbol may be used on its own. Careful consideration should be taken when
        using the symbol instead of the master logo. To help decide, ask yourself: Will my audience know the octopus
        represents Artisan? Is the written name “Artisan” in close enough proximity for reference? The symbol may be
        used for social media profile avatars, internal branded schwag, and for secret society branded collateral.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="brand mark - marine"
            className="block mx-auto w-full"
            src={logoMarkMarine}
          />
          <figcaption className="text-sm uppercase mt-1">Marine Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - arctic"
            className="block mx-auto w-full"
            src={logoMarkArctic}
          />
          <figcaption className="text-sm uppercase mt-1">Arctic Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - black"
            className="block mx-auto w-full"
            src={logoMarkBlack}
          />
          <figcaption className="text-sm uppercase mt-1">Black Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - white"
            className="block mx-auto w-full"
            src={logoMarkWhite}
          />
          <figcaption className="text-sm uppercase mt-1">White Symbol</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        CIRCLE SYMBOL
      </h2>
      <p className="my-4 md:w-5/6 text-lg" tabIndex="0">
        The octopus logo symbol is also provided in a circle lockup to use as desired.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <img
            alt="brand mark - marine"
            className="block mx-auto w-full"
            src={logoSymbolCircleMarine}
          />
          <figcaption className="text-sm uppercase mt-1">Marine Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - arctic"
            className="block mx-auto w-full"
            src={logoSymbolCircleArctic}
          />
          <figcaption className="text-sm uppercase mt-1">Arctic Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - black"
            className="block mx-auto w-full"
            src={logoSymbolCircleBlack}
          />
          <figcaption className="text-sm uppercase mt-1">Black Symbol</figcaption>
        </figure>
        <figure>
          <img
            alt="brand mark - white"
            className="block mx-auto w-full"
            src={logoSymbolCircleWhite}
          />
          <figcaption className="text-sm uppercase mt-1">White Symbol</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        CORRECT LOGO USAGE
      </h2>
      <ol className="text-lg my-4 md:w-3/4 list-upperAlpha list-inside">
          <li>Whenever possible, use full color over a white background</li>
          <li>The Marine version of the logo may be used with its contrasting brand color</li>
          <li>The Arctic version of the logo may be used with its contrasting brand color</li>
          <li>Example of using a solid-colored version of the symbol</li>
      </ol>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <div className="relative">
            <img
              alt="correct logo usage"
              className="block mx-auto w-full"
              src={logoCorrect1}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">A</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="correct logo usage"
              className="block mx-auto w-full"
              src={logoCorrect2}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">B</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="correct logo usage"
              className="block relative mx-auto w-full border border-solid border-primary_color"
              src={logoCorrect3}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">C</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="correct logo usage"
              className="block relative mx-auto w-full"
              src={logoCorrect4}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">D</figcaption>
        </figure>
      </div>
      <h2 className="heading-2" tabIndex="0">
        INCORRECT LOGO USAGE
      </h2>
      <ol className="text-lg my-4 md:w-3/4 list-upperAlpha list-inside">
          <li>Never change the color(s) of the logo</li>
          <li>Never use non-brand colors for logo background</li>
          <li>Never use the logo text by itself</li>
          <li>Never mix the symbol and logo text</li>
      </ol>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 my-4">
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block mx-auto w-full"
              src={logoIncorrect1}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">A</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block mx-auto w-full"
              src={logoIncorrect2}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">B</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full border border-solid border-primary_color"
              src={logoIncorrect3}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">C</figcaption>
        </figure>
        <figure>
          <div className="relative">
            <img
              alt="incorrect logo usage"
              className="block relative mx-auto w-full"
              src={logoIncorrect4}
            />
          </div>
          <figcaption className="text-sm mt-1 uppercase">D</figcaption>
        </figure>
      </div>
      <div className="flex flex-col md:flex-row md:h-16 mt-16">
        <a href={`/downloads/ART_LOGO.zip`} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block md:align-middle">Download Logo Pack (All logo variations and file formats)</p>
      </div>
      <div className="flex flex-col md:flex-row md:h-16 mt-8">
        <a href={`/downloads/ART_LOGO.pdf`} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block md:align-middle">Download Logo Specs (PDF)</p>
      </div>
    </section>
  );
}

export default LogoSection;