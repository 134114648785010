import React from "react";

// images
// no images

function ColorSection() {
  return (
    <section className="text-left mt-20 w-full" id="color">

      <div className="bg-white sticky top-0 z-10">
        <h1 className="heading-1" tabIndex="0">
          COLOR
        </h1>
      </div>
      <p className="my-4 md:w-5/6" tabIndex="0">
        Our brand color palette is cool and refreshing. While nautical, it also conveys a light and refreshing feel.
      </p>
      <h2 className="heading-2" tabIndex="0">
        PRIMARY COLORS
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Marine Navy and Arctic Blue are our primary brand colors. Each may be used often and in tandem. 
      </p>
      <div className="grid grid-cols-2 gap-8 my-4 font-second_font font-light text-2xl">
        <div>
          <figure>
            <div className="bg-primary_color w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">Marine Navy
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 w-4/5 md:w-2/3">
              <div>HEX</div> <div>#0C1B3F</div>
              <div>CMYK</div> <div>100,90,41,52</div>
              <div>PANTONE</div> <div>2767</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
        <div>
          <figure>
            <div className="bg-second_color w-full h-48"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">Arctic Blue
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-tight uppercase">
            <div className="grid grid-cols-2 gap-2 md:w-2/3">
              <div>HEX</div> <div>#0A97AD</div>
              <div>CMYK</div> <div>80,23,27,0</div>
              <div>PANTONE</div> <div>2209</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      <h2 className="heading-2" tabIndex="0">
        SECONDaRY COLoR
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        Ice Grey is used to help break up white space when needed in backgrounds.
      </p>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8 my-4 font-second_font font-light text-2xl">
        <div>
          <figure>
            <div className="bg-s1 w-full h-32"></div>
            <figcaption className="text-sm mt-1 font-medium uppercase">Ice Grey
            </figcaption>
          </figure>
          <div className="flex mt-3 font-second_font font-light text-sm leading-relaxed uppercase">
            <div className="flex flex-wrap w-full">
              <div className="w-1/2">HEX</div> <div className="w-1/2">#EFEFEF</div>
              <div className="w-1/2">CMYK</div> <div className="w-1/2">5,3,3,0</div>
              <div className="w-1/2">PANTONE</div> <div className="w-1/2">N/A</div>
            </div>
            <div className="w-1/5 md:w-1/3"></div>
          </div>
        </div>
      </div>
      <h2 className="heading-2" tabIndex="0">
        COLoR USaGE RATiO
      </h2>
      <p className="text-lg my-4 md:w-5/6" tabIndex="0">
        This ratio shows how much you should use each of our brand colors. Marine Navy and Arctic Blue are used 
        most often, whereas Ice Grey is used only when needed.
      </p>
      <div className="flex mb-4">
        <div className="bg-primary_color h-40 font-primary_font text-second_color uppercase text-xl relative"
        style={{width: 45 + '%'}}>
          <div className="w-40 absolute bottom-0 transform -rotate-90 origin-top-left -mb-5 ml-6">Marine Navy 45%</div>
        </div>
        <div className="bg-second_color w-2/5 h-40 font-primary_font text-primary_color uppercase text-xl relative">
          <div className="w-40 absolute bottom-0 transform -rotate-90 origin-top-left -mb-5 ml-6">Arctic Blue 40%</div>
        </div>
        <div className="bg-s1 h-40 font-primary_font text-primary_color uppercase text-xl relative" style={{width: 15 + '%'}}>
          <div className="w-40 absolute bottom-0 transform -rotate-90 origin-top-left -mb-5 ml-6">Ice Grey 15%</div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={`/downloads/ART_COLOR.pdf`} className="no-underline" download>
          <button className="btn" id="download-logo-mark">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download Color Specs (PDF)</p>
      </div>
    </section>
  );
}

export default ColorSection;