import React from "react";

// images
// no images

function TypographySection() {
  return (
    <section className="text-left mt-20 w-full" id="typography">

      <div className="bg-white sticky top-0">
        <h1 className="heading-1" tabIndex="0">
          typography
        </h1>
      </div>
      <p className="font-second_font my-4 md:w-5/6" tabIndex="0">
        ‘Proxima Nova Condensed’ is our brand font and should be used as often as possible. This font is simple, 
        bold, impactful and pairs well with our logo. We use the Black Italic and Regular font weights most often. 
        Headlines and CTAs should always be italicized.
      </p>
      <h2 className="heading-2" tabIndex="0">
          primary font
      </h2>
      <figure className="leading-none">
        <p className="font-primary_font text-primary_color bg-s1 text-6xl md:text-9xl font-black italic 
        pl-8 pt-8 pb-8 mt-1 uppercase">display headline.</p>
        <figcaption className="font-second_font font-light text-sm mt-2">
          DISPLAY HEADLINE, PROXIMA NOVA CONDENSED BLACK ITALIC, 42PT, CAPS</figcaption>
      </figure>
      <figure className="leading-none">
        <p className="font-primary_font text-primary_color bg-s1 text-5xl pl-8 pt-8 pb-8 mt-12">
          Secondary Headline.</p>
        <figcaption className="font-second_font font-light text-sm mt-2">
          SECONDARY HEADLINES, PROXIMA NOVA CONDENSED REGULAR, 18PT, TITLE CASE</figcaption>
      </figure>

      <figure className="leading-tight my-4 pt-4">
        <p className="leading-normal font-second_font bg-s1 text-lg font-light pl-1 md:pr-48 pl-8 pt-8 pb-8 my-2">Paragraph text,
        gravida mi nibh, a auctor enim tempor non. Sed non ultrices neque. Nunc lectus lorem, condimentum vitae
        sem sit amet, lobortis ullamcorper nisi. Nam odio lorem, varius vel lacus id, ullamcorper suscipit arcu. 
        Praesent vel diam nec augue porttitor molestie. Nam sed justo ac est sodales hendrerit quis nec eros. 
        Mauris non massa vitae nunc tincidunt egestas. Vivamus enim nulla, pellentesque id pharetra sed, lacinia
        at nulla. Maecenas nec suscipit libero. Nunc tempor nulla eu urna tempor, ut pharetra nulla pretium.</p>
        <figcaption className="font-second_font font-light text-sm">
          PARAGRAPH TEXT, PROXIMA NOVA CONDENSED REGULAR, 12PT, SENTENCE CASE</figcaption>
      </figure>
      <div className="flex flex-col md:flex-row h-16 mt-16">
        <a href={`/downloads/ART_TYPOGRAPHY.pdf`} className="no-underline" download>
          <button className="btn" id="download-font-sheet">download</button>
        </a>
        <p className="my-4 md:ml-8 inline-block align-middle">Download Typography Specs & Sample (PDF)</p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8">
        <a href={`https://fonts.adobe.com/fonts/proxima-nova`} className="no-underline" rel="noopener noreferrer" target="_blank">
          <button className="btn" id="download-font-package">activate</button>
        </a>
        <p className="my-4 md:w-1/2 md:my-0 md:ml-8 inline-block align-top">Activate the ‘Proxima Nova’ font via Adobe Fonts for print and digital 
        use if you have an Adobe CC account</p>
      </div>
      <div className="flex flex-col md:flex-row h-16 mt-8">
        <a href={`https://www.myfonts.com/fonts/marksimonson/proxima-nova/`} className="no-underline" rel="noopener noreferrer" target="_blank">
          <button className="btn" id="download-font-package">download</button>
        </a>
        <p className="my-4 md:w-1/2 md:my-0 md:ml-8 inline-block align-top">Purchase the ‘Proxima Nova’ font via MyFonts for print and digital 
        use if you do not have an Adobe CC account</p>
      </div>
    </section>
  );
}

export default TypographySection;